/*!

=========================================================
* Now UI Kit Pro Angular - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-angular
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import 'now-ui-kit/variables';
@import 'now-ui-kit/mixins';

// Plugins CSS
@import "now-ui-kit/plugins/plugin-bootstrap-switch";
@import "now-ui-kit/plugins/plugin-nouislider";
@import "now-ui-kit/plugins/plugin-isometricGrids";
@import "now-ui-kit/plugins/plugin-bootstrap-select";
@import "now-ui-kit/plugins/plugin-tagsinput";
@import "now-ui-kit/plugins/plugin-fileupload";
@import "now-ui-kit/plugins/plugin-datetimepicker";

// Core CSS
@import "now-ui-kit/buttons";
@import "now-ui-kit/inputs";
@import "now-ui-kit/checkboxes-radio";
@import "now-ui-kit/progress";
@import "now-ui-kit/badges";
@import "now-ui-kit/pagination";
@import "now-ui-kit/typography";
@import "now-ui-kit/misc";
@import "now-ui-kit/pills";

// components
@import "now-ui-kit/tabs";
@import "now-ui-kit/navbar";
@import "now-ui-kit/dropdown";
@import "now-ui-kit/alerts";
@import "now-ui-kit/images";
@import "now-ui-kit/popups";
@import "now-ui-kit/nucleo-outline";
@import "now-ui-kit/tables";
@import "now-ui-kit/info-areas";
@import "now-ui-kit/media";
@import "now-ui-kit/modals";
@import "now-ui-kit/carousel";
@import "now-ui-kit/cards";
@import "now-ui-kit/footers";

// example pages and sections
@import "now-ui-kit/example-pages";
@import "now-ui-kit/sections";

@import "now-ui-kit/responsive";
