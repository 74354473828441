.dropdown-menu{
    border: 0;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius-extra-small;
    @include transition($fast-transition-time, $transition-linear);
    font-size: $font-size-base;
    top: 0 !important;
    left: auto !important;
    &.dropdown-menu-right{
        &:before{
            left:auto;
            right: 10px;
        }
    }

    i{
        margin-right: 5px;
        position: relative;
        top: 1px;
    }

    .now-ui-icons{
        margin-right: 10px;
        position: relative;
        top: 4px;
        font-size: 18px;
        margin-top: -5px;
        opacity: .5;
    }

    .dropdown-item{
        &.active,
        &:active{
            color: inherit;
        }
    }

    .dropup &{
        &:before{
            display: none;
        }

        &:after{
            display: inline-block;
            position: absolute;
            width: 0;
            height: 0;
            vertical-align: middle;
            content: "";
            top: auto;
            bottom: -5px;
            right: auto;
            left: 10px;
            color: $white-color;
            border-top: .4em solid;
            border-right: .4em solid transparent;
            border-left: .4em solid transparent;
        }

        &.dropdown-menu-right{
            &:after{
                right: 10px;
                left: auto;
            }
        }

    }

    &:before{
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: "";
        top: -5px;
        left: 10px;
        right: auto;
        color: $white-color;
        border-bottom: .4em solid;
        border-right: .4em solid transparent;
        border-left: .4em solid transparent;
    }

    .dropdown-item,
    .bootstrap-select &.inner li a{
        font-size: $font-size-small;
        padding-top: .6rem;
        padding-bottom: .6rem;
        margin-top: 5px;
        @include transition($fast-transition-time, $transition-linear);

        &:hover,
        &:focus{
            background-color: $opacity-gray-3;
        }

        &.disabled,
        &:disabled{
            color: $default-color-opacity;

            &:hover,
            &:focus{
                background-color: transparent;
            }
        }
    }


    .dropdown-divider{
        background-color: $opacity-gray-5;
    }

    .dropdown-header:not([href]):not([tabindex]){
        color: $default-color-opacity;
        font-size: $font-size-mini;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    &.dropdown-primary{
        @include dropdown-colors(darken($primary-color, 3%),$opacity-8,$white-color, $opacity-2);
    }

    &.dropdown-info{
        @include dropdown-colors(darken($info-color, 3%),$opacity-8,$white-color, $opacity-2);
    }

    &.dropdown-danger{
        @include dropdown-colors(darken($danger-color, 3%),$opacity-8,$white-color, $opacity-2);
    }

    &.dropdown-success{
        @include dropdown-colors(darken($success-color, 3%),$opacity-8,$white-color, $opacity-2);
    }

    &.dropdown-warning{
        @include dropdown-colors(darken($warning-color, 3%),$opacity-8,$white-color, $opacity-2);
    }

    .dropdown &,
    .dropup:not(.bootstrap-select) &,
    .bootstrap-select &:not(.inner),
    &.bootstrap-datetimepicker-widget.top,
    &.bootstrap-datetimepicker-widget.bottom{
        @include transform-translate-y-dropdown(20px);
        visibility: hidden;
        display: block;
        @include opacity(0);
        top:0;
    }

    .dropdown.show &,
    .bootstrap-select.show &:not(.inner),
    &.bootstrap-datetimepicker-widget.top.open,
    &.bootstrap-datetimepicker-widget.bottom.open,
    .dropup.show:not(.bootstrap-select) &{
        @include opacity(1);
        visibility: visible;
        @include transform-translate-y-dropdown(48px);
    }

    &.bootstrap-datetimepicker-widget.top,
    &.bootstrap-datetimepicker-widget.bottom.open{
        @include transform-translate-y-dropdown(0px);
    }


    &.bootstrap-datetimepicker-widget.top.open{
        @include transform-translate-y-dropdown(-22px);
    }

    &.bootstrap-datetimepicker-widget.bottom{
        @include transform-translate-y-dropdown(-20px);
    }

    .bootstrap-select.dropup &:not(.inner){
        @include transform-translate-y-dropdown(25px);
    }

    .dropup:not(.bootstrap-select) &{
        @include transform-translate-y-dropdown(-112px);
    }


    .dropup.show:not(.bootstrap-select) &{
        @include transform-translate-y-dropdown(-131px);
    }

    .navbar .dropdown.show &{
        @include transform-translate-y-dropdown(43px);
    }
}

.button-dropdown{
    padding-right: $padding-base-horizontal;
    cursor: pointer;

    & .dropdown-toggle{
        padding-top: $padding-base-vertical;
        padding-bottom: $padding-base-vertical;
        display: block;

        &:after{
            display: none;
        }
    }
}
