.nav-pills-icons{
  .nav-pills .nav-item .nav-link{
    border-radius: .25em !important;
  }
}

.nav-pills{

    &.flex-column li > a{
        margin-bottom: 15px;
    }

    &:not(.nav-pills-just-icons) .nav-item .nav-link{
        border-radius: $btn-round-radius;
    }

    &.nav-pills-just-icons .nav-item .nav-link{
        border-radius: 50%;
        height: 80px;
        width: 80px;
        padding: 0;
        max-width: 80px;
        min-width: auto;

        i.now-ui-icons{
            line-height: 80px;
            font-size: 24px;
        }
    }

    &:not(.flex-column) .nav-item {
        .nav-link{
            margin-right: 19px;
        }
    }

    .nav-item {

        .nav-link{
            padding: 0 15.5px;
            text-align: center;
            padding: $padding-btn-vertical $padding-round-horizontal;
            min-width: 100px;
            font-weight: $font-weight-normal;
            color: $light-black;
            background-color: $opacity-gray-3;


            &:hover{
                background-color: $opacity-gray-3;
            }

            &.active{
                &,
                &:focus,
                &:hover{
                    background-color: $dark-gray;
                    color: $white-color;
                    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
                }
            }

            &.disabled,
            &:disabled,
            &[disabled]{
                opacity: .5;
            }
        }

        i{
            display: block;
            font-size: 20px;
            line-height: 60px;
        }
    }

    &.nav-pills-neutral{
        .nav-item {
            .nav-link{
                background-color: $opacity-2;
                color: $white-color;

                &.active{
                    &,
                    &:focus,
                    &:hover{
                        background-color: $white-color;
                        color: $primary-color;
                    }
                }
            }
        }
    }

    &.nav-pills-primary{
        .nav-item {
            .nav-link.active{
                &,
                &:focus,
                &:hover{
                    background-color: $brand-primary;
                }
            }
        }
    }

    &.nav-pills-info{
        .nav-item {
            .nav-link.active{
                &,
                &:focus,
                &:hover{
                    background-color: $brand-info;
                }
            }
        }
    }

    &.nav-pills-success{
        .nav-item {
            .nav-link.active{
                &,
                &:focus,
                &:hover{
                    background-color: $brand-success;
                }
            }
        }
    }

    &.nav-pills-warning{
        .nav-item {
            .nav-link.active{
                &,
                &:focus,
                &:hover{
                    background-color: $brand-warning;
                }
            }
        }
    }

    &.nav-pills-danger{
        .nav-item {
            .nav-link.active{
                &,
                &:focus,
                &:hover{
                    background-color: $brand-danger;
                }
            }
        }
    }
}

.nav-align-center{
    text-align: center;

    .nav-pills{
        display: inline-flex;
    }
}
.tab-space{
  .tab-content{
    padding: 20px 0 50px 0px;
  }
}
.nav-pills-wrapper{
  .vertical-pills{
      &.nav-pills{
          border-bottom: none !important;
          &:not(.nav-pills-icons):not(.nav-pills-just-icons) .flex-column{
              width: 33%;

              .nav-link.active:before{
                  border-right: 11px solid #F1EAE0;
                  border-top: 11px solid transparent;
                  border-bottom: 11px solid transparent;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  right: 0;
                  bottom: 22px;
              }
              .nav-link.active:after{
                  border-right: 11px solid #FFFFFF;
                  border-top: 11px solid transparent;
                  border-bottom: 11px solid transparent;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  right: -1px;
                  bottom: 22px;
              }
          }
      }

      .tab-content{
          width: 66%;
          float: left;
          margin-left: 5px;
      }
  }
  .tab-content{
    padding: 1.25rem;
  }
}
